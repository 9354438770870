<template>
	<form class="form form--user form-user" @submit.prevent="submitForm(fields)">
		<div class="form__loader" v-if="loading || isLoading">
			<loading-spinner />
		</div>

		<div class="form-section">
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="First Name" v-model="fields.firstName" :required="true" />
				<text-field class="form__field" label="Last Name" v-model="fields.lastName" :required="true" />
				<text-field class="form__field" label="Email Address" type="email" autocomplete="email" v-model="fields.email" :required="true" />
				<text-field class="form__field" label="Phone" v-model="fields.phone" />
				<select-field class="form__field" label="Status" v-model="fields.status" :options="userStatus" />
				<autocomplete-organization @selected="orgSelect" :value="fields.orgId" :label="fields.organization | organization" />
				<!-- <select-field class="form__field" label="Organization" v-model="fields.orgId" :options="organizations" /> -->
			</div>

			<h2 class="form-section__title">Address Information</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Street" v-model="fields.address.line1" />
				<text-field class="form__field" label="Street (cont)" v-model="fields.address.line2" />
				<text-field class="form__field" label="City" v-model="fields.address.city" />
				<select-field class="form__field" label="State" v-model="fields.address.state" :options="states" />
				<text-field class="form__field" label="Postal Code" v-model="fields.address.postal" />
			</div>

			<h2 class="form-section__title">Settings</h2>
			<div class="form-row form-row--col-2">
				<text-field class="form__field" label="Job Title" v-model="fields.settings.jobTitle" />
			</div>

			<div class="alert alert--fail" v-if="error">
				{{ error }}
			</div>
			<div class="form__actions form__actions--horizontal">
				<a-button class="button--primary form__action" type="submit" :disabled="loading || isLoading">
					{{ fields.uid ? 'Update' : 'Create' }} User
				</a-button>
			</div>

		</div>
	</form>
</template>

<script>
import AButton from '@/components/AButton'
import AutocompleteOrganization from '@/components/AutocompleteOrganization'
import Checkbox from '@/components/Checkbox'
import LoadingSpinner from '@/components/LoadingSpinner'
import SelectField from '@/components/SelectField'
import TextField from '@/components/TextField'
import { UserStatus } from '@/lib/enums'
import stateList from '@/assets/stateList.json'
import { get, pick } from 'lodash'

export default {
	name: 'FormUser',
	components: {
		AButton,
		AutocompleteOrganization,
		Checkbox,
		LoadingSpinner,
		SelectField,
		TextField,
	},
	props: {
		loading: Boolean,
		user: {
			type: Object,
			default: () => ({}),
		},
	},
	data: () => ({
		defaults: {
			address: {},
			settings: {},
		},
		error: null,
		fields: {},
		isLoading: false,
		states: stateList,
	}),
	computed: {
		me() {
			return this.$store.getters['user/me']
		},
		userStatus() {
			let status = []
			for (let key in UserStatus) {
				status.push({
					value: key,
					label: UserStatus[key],
				})
			}

			return status
		},
	},
	methods: {
		orgSelect(selected) {
			this.fields.orgId = selected.value
			this.fields.orgSearch = selected.label
		},
		submitForm(fields) {
			this.isLoading = true
			if (fields.uid) {
				this.$store.dispatch('user/update', { uid: fields.uid, data: fields }).then(() => {
					this.$notice(`User updated!`)
				}).catch(error => {
					this.error = error.message || error
				})
			} else {
				this.$store.dispatch('user/create', fields).then(() => {
					this.$notice(`User created!`)
				}).catch(error => {
					this.error = error.message || error
				})
			}
			this.isLoading = false
		},
		remove(fields) {
			if (!fields.uid) return

			this.$confirm(`Are you sure you want to remove "${fields.firstName} ${fields.lastName}"?`, () => {
				this.$store.dispatch('user/remove', fields.uid).then(() => {
					this.$notice(`User removed!`)
					this.$router.back()
				}).catch(error => {
					this.$notice(`ERROR: ${error.message || error}`)
				})
			})
		},
		importMock() {
			for (const user of mockUsers) {
				// console.log('user', user)
				this.$store.dispatch('user/create', user)
			}
		},
	},
	watch: {
		user: {
			immediate: true,
			handler: function( userProp) {
				this.fields = {
					...this.defaults,
					...this.fields,
					...pick(userProp, ['uid', 'address', 'email', 'firstName', 'lastName', 'organization', 'phone', 'settings', 'status',]),
					// ...{ orgId: get(userProp, 'organization.orgId') },
				}
			}
		},
	},
}
</script>

<style scoped lang="scss">
.form-user {
}
</style>
